import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStripe } from '@stripe/react-stripe-js';
import { useStaticQuery, graphql } from 'gatsby';

const jsonToQueryString = (json) => (
    Object.entries(json).map(([key, value]) => (
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )).join('&')
);

const Field = ({ type="text", error, children, name, register }) => (    
        <div className="field text">
            <label htmlFor={name}>{children}</label>
            <input type={type} name={name} id={name} ref={register} />
            {error && <div className="error-message">This field is required</div>}
        </div>
);

const OrderForm = () => {
    const { register, errors, handleSubmit, watch } = useForm();
    const stripe = useStripe();
    const [error, setError] = useState(null);
    const [ processing, setProcessing ] = useState(false);
    const data = useStaticQuery(graphql`
      query FormQuery {
        site {
          siteMetadata {
            siteURL
            bookSKU
          }
        }
      }
    `);
    const { site: { siteMetadata: { siteURL, bookSKU } } } = data;

    const q = parseInt(watch('qty', 1), 10);
    const price = (q * 25).toFixed(2);
        
    const options = Array.from({length: 10});

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setError(null);
        setProcessing(true);
        try {
            await fetch(event.target.action, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: jsonToQueryString({...data, 'form-name': 'order'}),
            });
            await stripe.redirectToCheckout({
                items: [
                    { sku: bookSKU, quantity: q },
                ],
                successUrl: `${siteURL}/success`,
                cancelUrl: `${siteURL}/cancel`,
            });
            setProcessing(false);
        } catch (e) {
            console.error(e);
            setError('There was a problem submitting your order. Please contact me on the "Contact" page.')
            setProcessing(false);
            return;
        }
    };
  
    return (
        <form name="order" data-netlify="true" method="POST" action={`${siteURL}/order/`} onSubmit={handleSubmit(onSubmit)}>
              {error && (
                <ul className="error-messages error-messages--active"><li>{error}</li></ul>
              )}

        <fieldset className="fields">
          <Field register={register} error={errors.name} name="name">Your name</Field>
          <Field register={register} error={errors.email} name="email">Your email</Field>
          <h3>Shipping information</h3>
          <Field register={register} error={errors.address} name="address">Address</Field>
          <Field register={register} error={errors.suburb} name="suburb">Suburb</Field>
          <Field register={register} error={errors.city} name="city">City</Field>
          <Field register={register} error={errors.postcode} name="postcode">Post code</Field>
          {/* <h3>Payment</h3>                    
          <CardElement 
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}                    
          /> */}
          <div className="field select">
            <label htmlFor="qty">Quantity: </label> 
            <select name="qty" ref={register}>
              {options.map((_, i) => (
                <option key={i} value={i+1}>{i+1}</option>
              ))}
            </select>
          </div>
          <input type="hidden" name="form-name" value="order" />
          <div className="actions">
            <button disabled={processing} type="submit" className="btn btn--primary btn--lg btn--full">
                {processing ? `Processing...` : `Pay $${price}`}
            </button>
          </div>
        </fieldset>
        <style jsx>{`
            .select {
              margin: 1rem 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .select label {
              flex-grow: 1;
            }
            .select select {
                margin-left: 2rem;
                width: 6rem;
            }
            h3 {
              margin: 2rem 0;
            }          
        `}</style>
      </form>

    )
};

export default OrderForm;