/* eslint-disable react/jsx-indent */
import React, { useMemo } from 'react';
import Layout from '../templates/Page';
import SEO from '../templates/components/SEO';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import css from 'styled-jsx/css';
import Sidebar from '../templates/components/Sidebar';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import OrderForm from '../templates/components/OrderForm';

const { styles, className } = css.resolve`
    .gatsby-image-wrapper {
        flex-grow: 1;
        max-width: 300px;
    }
`;

const OrderPage = ({ data }) => {
    const stripePromise = useMemo(() => (
      loadStripe(data.site.siteMetadata.stripeKey)
    ), []);

    return (
		<Layout>
		  <SEO title={`Order Woman Enough by Lissa Carlino. Free shipping`} />
		  <header className="page-header">
		    <div className="page-header__wrapper">
		      <h1 className="page-header__title">Order Now</h1>
		    </div>
		  </header>
		  <div className="block block--xl-on-md">
		    <div className="blog-page__wrapper">		      
          <div className="page-cols">
            <div className="page-col">
              <div className="img">
                <Img className={className} fluid={data.wordpressWpMedia.localFile.childImageSharp.fluid} />
                {styles}
              </div>
              <div className="details">
                <h3>Woman Enough</h3>
                <h4>NZD $25.00 (free shipping)</h4>
                <p>For more information, including reviews, local retailers, international and 
                    retailer purchases, please visit the <Link to="/order-information">order information page</Link>.
                </p>
              </div>
            </div>
            <Sidebar title="Order form">
              <Elements stripe={stripePromise}>
                <OrderForm />              
              </Elements>
            </Sidebar>
            
          </div>
		    </div>
		  </div>
          <style jsx>{`
            .img {
                display: flex;
                justify-content: center;
            }
            .details {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                text-align:center;
            }
          `}</style>
		</Layout>
		
    );
};

export default OrderPage;

export const pageQuery = graphql`
{
    site {
      siteMetadata {
        stripeKey
      }
    }
    wordpressWpMedia(title: { eq: "Woman-Enough-front" }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;